import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  measurementId: 'G-LH233F1MWW',
  projectId: 'gading-murni-476e7',
  messagingSenderId: '406757309596',
  storageBucket: 'gading-murni-476e7.appspot.com',
  authDomain: 'gading-murni-476e7.firebaseapp.com',
  apiKey: 'AIzaSyAk-yi5-VDSMarp5LjXOftxWvqG5aHveO8',
  appId: '1:406757309596:web:a6b0f8d1e918911bdcc159',
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging, firebaseConfig };
