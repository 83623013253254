import React from 'react';

import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig';

export const publicRoutes = [
  {
    key: 'login',
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() => import('views/auth-views/login')),
  },
  {
    key: 'request-reset-password',
    path: `${AUTH_PREFIX_PATH}/request-reset-password`,
    component: React.lazy(
      () => import('views/auth-views/request-reset-password')
    ),
  },
  {
    key: 'reset-password',
    path: `${AUTH_PREFIX_PATH}/reset-password/:email`,
    component: React.lazy(() => import('views/auth-views/reset-password')),
  },
];

export const protectedRoutes = [
  {
    key: 'dashboard',
    path: `${APP_PREFIX_PATH}/dashboard`,
    meta: {
      showAccess: true,
    },
    component: React.lazy(() => import('views/app-views/dashboard')),
  },
  {
    key: 'accessSetting',
    path: `${APP_PREFIX_PATH}/system/access-setting`,
    component: React.lazy(
      () => import('views/app-views/system/access-setting')
    ),
  },
  {
    key: 'userSetting',
    path: `${APP_PREFIX_PATH}/system/user-setting`,
    component: React.lazy(() => import('views/app-views/system/user-setting')),
  },
  {
    key: 'adminCs',
    path: `${APP_PREFIX_PATH}/admin-cs`,
    component: React.lazy(() => import('views/app-views/admin-cs')),
  },
  {
    key: 'adminCs-booking-add',
    path: `${APP_PREFIX_PATH}/admin-cs/booking/add`,
    component: React.lazy(() => import('views/app-views/admin-cs/booking/add')),
  },
  {
    key: 'adminCs-booking-view',
    path: `${APP_PREFIX_PATH}/admin-cs/booking/:id`,
    component: React.lazy(
      () => import('views/app-views/admin-cs/booking/view')
    ),
  },
  {
    key: 'adminCs-booking-edit',
    path: `${APP_PREFIX_PATH}/admin-cs/booking/:id/edit`,
    component: React.lazy(
      () => import('views/app-views/admin-cs/booking/edit')
    ),
  },
  {
    key: 'headTechnician',
    path: `${APP_PREFIX_PATH}/head-technician`,
    component: React.lazy(() => import('views/app-views/head-technician')),
  },
  {
    key: 'headTechnician-booking-view',
    path: `${APP_PREFIX_PATH}/head-technician/booking/:id`,
    component: React.lazy(
      () => import('views/app-views/head-technician/booking/view')
    ),
  },
  {
    key: 'technician',
    path: `${APP_PREFIX_PATH}/technician`,
    component: React.lazy(() => import('views/app-views/technician')),
  },
  {
    key: 'technician-booking-view',
    path: `${APP_PREFIX_PATH}/technician/booking/:id`,
    component: React.lazy(
      () => import('views/app-views/technician/booking/view')
    ),
  },
  {
    key: 'customer',
    path: `${APP_PREFIX_PATH}/customer`,
    component: React.lazy(() => import('views/app-views/customer')),
  },
  {
    key: 'customer-add',
    path: `${APP_PREFIX_PATH}/customer/add`,
    component: React.lazy(() => import('views/app-views/customer/view/add')),
  },
  {
    key: 'machine',
    path: `${APP_PREFIX_PATH}/machine`,
    component: React.lazy(() => import('views/app-views/machine')),
  },
  {
    key: 'customer-view',
    path: `${APP_PREFIX_PATH}/customer/:id`,
    component: React.lazy(() => import('views/app-views/customer/view')),
  },
  {
    key: 'profile',
    path: `${APP_PREFIX_PATH}/profile`,
    meta: {
      showAccess: true,
    },
    component: React.lazy(() => import('views/app-views/profile')),
  },
  {
    key: 'notification',
    meta: {
      showAccess: true,
    },
    path: `${APP_PREFIX_PATH}/notification`,
    component: React.lazy(() => import('views/app-views/notification')),
  },
];
