import { getToken, onMessage } from 'firebase/messaging';

import { messaging } from './FirebaseConfig';

const requestForToken = async () => {
  let currentToken = '';

  try {
    if (!('Notification' in window)) {
      // console.log('This browser does not support desktop notification');
    } else if (Notification.permission === 'granted') {
      // Register the service worker for Firebase Messaging
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker
          .register('/firebase-messaging-sw.js') // Ensure this path is correct
          .then((registration) => {
            console.log(
              'Service Worker registered with scope:',
              registration.scope
            );
          })
          .catch((error) => {
            console.error('Service Worker registration failed:', error);
          });
      }
      currentToken = await getToken(messaging, {
        vapidKey:
          'BA6JXKScRlX9_nQkVXB-8ceGnOAdgRp1ITe6UMBakk_MtLirePI9EtNEjNgsPg2hkC3CK5xzdYZ1tmwMfgKcb5E', // Ganti dengan VAPID key dari Firebase Console
      });
      if (currentToken) {
        // Simpan token ke backend atau gunakan untuk notifikasi
      } else {
        // console.log(
        //   'No registration token available. Request permission to generate one.'
        // );
      }
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          // console.log('Notification is granted');
          // …
        }
      });
    }
  } catch (error) {
    console.error('An error occurred while retrieving token. ', error);
  }

  return currentToken;
};

const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export { requestForToken, onMessageListener };
