import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, notification } from 'antd';
import { useGetMyProfile } from 'services/api/account';
import { onBlankLayout } from 'store/slices/themeSlice';
import {
  requestForToken,
  onMessageListener,
} from 'configs/FirebaseNotification';

const AppRoute = ({ blankLayout, component: Component, ...props }) => {
  const token = useSelector((state) => state.auth?.user);
  const type = useSelector((state) => state.auth?.user?.type);
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const redirectUser = () => {
    let path = 'admin-cs';
    if (type === 'HEAD_TECH') {
      path = 'head-technician';
    }
    return path;
  };
  const openNotification = (payload) => {
    // const key = `open${Date.now()}`;
    const btn = (
      <Link to={`/app/${redirectUser()}/booking/${payload.data.id}`}>
        <Button size="small" type="primary">
          Lihat Detail
        </Button>
      </Link>
    );
    api.open({
      btn,
      // key,
      // onClose: close,
      message: payload.notification.title,
      description: payload.notification.body,
    });
  };

  useGetMyProfile({
    enabled: Boolean(token && !blankLayout),
  });

  useEffect(() => {
    const isBlank = blankLayout ? true : false;
    dispatch(onBlankLayout(isBlank));
  }, [blankLayout, dispatch]);

  useEffect(() => {
    // Request permission and get token
    requestForToken();
  }, []);

  onMessageListener()
    .then((payload) => {
      openNotification(payload);
      // Tangani notifikasi ketika aplikasi berada di foreground
    })
    .catch((err) => console.log('Failed to receive foreground message: ', err));

  return (
    <>
      {contextHolder}
      <Component {...props} />
    </>
  );
};

export default AppRoute;
